@keyframes flick {
    from {
        transform: scaleY(1);
        opacity: 1;
    }
}

@keyframes appear {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes point-right {
    0% {
        transform: translateX(0)  scale(1) rotate(-45deg);
        opacity: 1;
    }

    25% {
        transform: translateX(10px) scale(0.9) rotate(-45deg);
        opacity: 0;
    }

    26% {
        transform: translateX(-10px) scale(0.9) rotate(-45deg);
        opacity: 0;
    }

    55% {
        transform: translateX(0) scale(1) rotate(-45deg);
        opacity: 1;
    }

    to {
        transform: rotate(-45deg);
    }
}

@keyframes sexy-face {
    from {
        transform: translate(-50%, 100%);
        opacity: 0;
    }

    20% {
        opacity: 1;
    }

    to {
        transform: translate(-50%, 0);
        opacity: 1;
    }
}
