@import "./colors";

.technology {
    padding: 1.5rem;

    &__container {
        max-width: 1050px;
        padding-top: 2rem;

        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        align-items: center;
        list-style: none;
    }

    &--frontend + &--backend,
    &--backend + &--database {
        padding-left: 2rem;
    }
}
