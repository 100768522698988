html {
    height: 100%;
    background-color: black;
}

body {
    width: 100%;
    height: 100vh;

    margin: 0;
    padding: 0;
    color: #fff;

    font-family: "Lato", Helvetica, sans-serif;
}

* {
    box-sizing: border-box;
}

ul,
li {
    margin: 0;
    padding: 0;
}
