h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.5rem;
}

html {
    font-size: 0.7rem;
}

@media (min-width: 400px) {
    html {
        font-size: 1rem;
    }
}

@media (min-width: 720px) {
    html {
        font-size: 1.4rem;
    }
}

@media (min-width: 1200px) {
    html {
        font-size: 1.8rem;
    }
}

@media (min-width: 1600px) {
    html {
        font-size: 1.4rem;
    }
}

h1,
h2,
h3 {
    margin: 0;
}

.linkless {
    color: inherit;
    text-decoration: none;
}
