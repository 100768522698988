.section {
    position: relative;
    padding: 0 16px;

    display: flex;
    flex: 1 0 auto;
    flex-flow: column wrap;

    transition: opacity 0.3s ease-in-out;

    &--full {
        width: 100%;
        height: 100%;
    }

    &--centered {
        justify-content: center;
        align-items: center;
    }

    &--disabled {
        opacity: 0;
    }

    &--active {
        opacity: 1;
    }
}

.scroll-section {
    height: 100%;
    overflow: auto;
    scroll-snap-type: y mandatory;

    &__stop {
        scroll-snap-align: center;
        scroll-snap-stop: always;
    }
}
