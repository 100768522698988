$arrow-size: 0.2rem;

.company {
    padding: 8px 16px;

    &:first-of-type {
        padding-top: 32px;
    }

    &__container {
        padding: 0;
        list-style: none;
    }

    &__name {
        color: #fff;
        text-decoration: none;
    }

    &__arrow {
        padding: $arrow-size;
        transform: rotate(-45deg);

        display: inline-block;

        border: solid currentColor;
        border-width: 0 $arrow-size $arrow-size 0;

        animation: 1.4s point-right infinite ease-in-out;

        &--2 {
            animation-delay: 0.2s;
        }
    }

    time {
        padding: 0 10px;

        &:first-of-type {
            padding-left: 0;
        }
    }
}
