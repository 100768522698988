.name {
    padding-bottom: 1rem;

    display: flex;
    align-items: center;
    word-spacing: 0;
}

// Not proud, but oh well
.👿 {
    font-size: 0;
}

.domain {
    position: relative;
    display: flex;
}

.domain__char {
    animation: appear 0.3s ease-in-out both;
}

.domain__char:first-child {
    animation-delay: 0.6s;
}

.domain__char:nth-child(2) {
    animation-delay: 1s;
}

.domain__char:nth-child(3) {
    animation-delay: 1.3s;
}

.domain__char:nth-child(4) {
    animation-delay: 1.7s;
}

.caret {
    width: 2px;
    margin-left: 1vw;
    transform: scaleY(0);

    transform-origin: center center;
    opacity: 0;
    background-color: #fff;
    animation: flick 0.5s ease-in-out alternate-reverse infinite both 2.2s;
}

.face {
    position: absolute;

    top: 0;
    left: 50%;
    width: 23%;

    z-index: -1;

    transform: translate(-50%, 100%);

    opacity: 0;
    object-fit: contain;
    border-radius: 48%;

    animation: sexy-face 1.4s ease-in-out both;

    &__container {
        position: relative;
        width: 20rem;
        z-index: 0;
        overflow: hidden;
        margin: 0 auto 2rem;

        display: flex;
        justify-content: center;

        @media (min-width: 720px) {
            width: 30%;
        }
    }

    &__wrapper {
        width: 100%;
    }
}

.body {
    position: relative;
    width: 100%;
}

.contacts {
    width: 100%;
    margin-top: 3rem;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    list-style: none;

    &__container {
        width: 100%;
        display: block;
    }

    &__item {
        display: flex;
        align-items: center;
        color: white;
    }

    &__icon {
        width: 1.5rem;
        height: 1.5rem;
        margin: 0 1rem;
        object-fit: contain;
    }
}
