html {
  height: 100%;
  background-color: #000;
}

body {
  width: 100%;
  height: 100vh;
  color: #fff;
  margin: 0;
  padding: 0;
  font-family: Lato, Helvetica, sans-serif;
}

* {
  box-sizing: border-box;
}

ul, li {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

html {
  font-size: .7rem;
}

@media (min-width: 400px) {
  html {
    font-size: 1rem;
  }
}

@media (min-width: 720px) {
  html {
    font-size: 1.4rem;
  }
}

@media (min-width: 1200px) {
  html {
    font-size: 1.8rem;
  }
}

@media (min-width: 1600px) {
  html {
    font-size: 1.4rem;
  }
}

h1, h2, h3 {
  margin: 0;
}

.linkless {
  color: inherit;
  text-decoration: none;
}

@keyframes flick {
  from {
    opacity: 1;
    transform: scaleY(1);
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes point-right {
  0% {
    opacity: 1;
    transform: translateX(0)scale(1)rotate(-45deg);
  }

  25% {
    opacity: 0;
    transform: translateX(10px)scale(.9)rotate(-45deg);
  }

  26% {
    opacity: 0;
    transform: translateX(-10px)scale(.9)rotate(-45deg);
  }

  55% {
    opacity: 1;
    transform: translateX(0)scale(1)rotate(-45deg);
  }

  to {
    transform: rotate(-45deg);
  }
}

@keyframes sexy-face {
  from {
    opacity: 0;
    transform: translate(-50%, 100%);
  }

  20% {
    opacity: 1;
  }

  to {
    opacity: 1;
    transform: translate(-50%);
  }
}

.background {
  width: 100%;
  height: 90%;
  z-index: -1;
  opacity: .5;
  object-fit: cover;
  filter: hue-rotate(45deg);
  transition: filter .4s ease-in-out;
  position: absolute;
  top: 5%;
  bottom: 5%;
  left: 0;
}

.background--1 {
  filter: hue-rotate(120deg);
}

.background--2 {
  filter: hue-rotate(240deg);
}

.background--3 {
  opacity: 0;
}

.company {
  padding: 8px 16px;
}

.company:first-of-type {
  padding-top: 32px;
}

.company__container {
  padding: 0;
  list-style: none;
}

.company__name {
  color: #fff;
  text-decoration: none;
}

.company__arrow {
  border: .2rem solid;
  border-width: 0 .2rem .2rem 0;
  padding: .2rem;
  animation: point-right 1.4s ease-in-out infinite;
  display: inline-block;
  transform: rotate(-45deg);
}

.company__arrow--2 {
  animation-delay: .2s;
}

.company time {
  padding: 0 10px;
}

.company time:first-of-type {
  padding-left: 0;
}

.name {
  word-spacing: 0;
  align-items: center;
  padding-bottom: 1rem;
  display: flex;
}

.👿 {
  font-size: 0;
}

.domain {
  display: flex;
  position: relative;
}

.domain__char {
  animation: appear .3s ease-in-out both;
}

.domain__char:first-child {
  animation-delay: .6s;
}

.domain__char:nth-child(2) {
  animation-delay: 1s;
}

.domain__char:nth-child(3) {
  animation-delay: 1.3s;
}

.domain__char:nth-child(4) {
  animation-delay: 1.7s;
}

.caret {
  width: 2px;
  transform-origin: center;
  opacity: 0;
  background-color: #fff;
  margin-left: 1vw;
  animation: flick .5s ease-in-out 2.2s infinite alternate-reverse both;
  transform: scaleY(0);
}

.face {
  width: 23%;
  z-index: -1;
  opacity: 0;
  object-fit: contain;
  border-radius: 48%;
  animation: sexy-face 1.4s ease-in-out both;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 100%);
}

.face__container {
  width: 20rem;
  z-index: 0;
  justify-content: center;
  margin: 0 auto 2rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media (min-width: 720px) {
  .face__container {
    width: 30%;
  }
}

.face__wrapper {
  width: 100%;
}

.body {
  width: 100%;
  position: relative;
}

.contacts {
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 3rem;
  list-style: none;
  display: flex;
}

.contacts__container {
  width: 100%;
  display: block;
}

.contacts__item {
  color: #fff;
  align-items: center;
  display: flex;
}

.contacts__icon {
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
  margin: 0 1rem;
}

.technology {
  padding: 1.5rem;
}

.technology__container {
  max-width: 1050px;
  flex-flow: wrap;
  justify-content: space-around;
  align-items: center;
  padding-top: 2rem;
  list-style: none;
  display: flex;
}

.technology--frontend + .technology--backend, .technology--backend + .technology--database {
  padding-left: 2rem;
}

.section {
  flex-flow: column wrap;
  flex: 1 0 auto;
  padding: 0 16px;
  transition: opacity .3s ease-in-out;
  display: flex;
  position: relative;
}

.section--full {
  width: 100%;
  height: 100%;
}

.section--centered {
  justify-content: center;
  align-items: center;
}

.section--disabled {
  opacity: 0;
}

.section--active {
  opacity: 1;
}

.scroll-section {
  height: 100%;
  scroll-snap-type: y mandatory;
  overflow: auto;
}

.scroll-section__stop {
  scroll-snap-align: center;
  scroll-snap-stop: always;
}

.interest__title {
  position: absolute;
  top: 2rem;
  left: 2rem;
}

.interest__subtitle {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
}

.lightbulb {
  height: 10vh;
  opacity: .2;
  filter: grayscale(.9);
  transition: opacity .3s ease-in-out, filter .3s ease-in-out;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%)rotate(180deg);
}

.lightbulb--active {
  opacity: 1;
  filter: none;
}

.cord__container {
  height: 20vh;
  cursor: pointer;
  justify-content: flex-start;
  align-items: stretch;
  transition: transform .2s ease-in-out;
  display: flex;
  position: absolute;
  top: 0;
  left: calc(50% + 6vh);
}

@media (hover: hover) {
  .cord__container:hover {
    transform: scale3d(1.1, 1.1, 1);
  }
}

.astronaut {
  width: 100%;
  height: 50vh;
}

/*# sourceMappingURL=index.86cb37c7.css.map */
