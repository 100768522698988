.lightbulb {
    position: absolute;
    top: 0;
    left: 50%;

    height: 10vh;

    transform: translateX(-50%) rotate(180deg);
    opacity: 0.2;
    filter: grayscale(0.9);
    transition: opacity 0.3s ease-in-out, filter 0.3s ease-in-out;

    &--active {
        opacity: 1;
        filter: none;
    }
}

.cord {
    &__container {
        position: absolute;
        top: 0;
        left: calc(50% + 6vh);
        height: 20vh;

        display: flex;
        justify-content: flex-start;
        align-items: stretch;

        cursor: pointer;

        transition: transform 0.2s ease-in-out;

        @media (hover: hover) {
            &:hover {
                transform: scale3d(1.1, 1.1, 1);
            }
        }
    }

}

.astronaut {
    width: 100%;
    height: 50vh;
}